import { CareerMentor } from "./CareerMentor";
import { Employee } from "./Employee";

export * from "./country";
export * from "./language";
export * from "./stateProvince";

export class TextLimit {
  id: number;
  fieldName: string;
  charLimit: number;
  isActive: boolean;
}

// export class Association
// {
//   assocId: number;
//   assocName: string;
//   isActive: boolean;
// }

// export class NonOnlinerAssociation
// {
//   assocId: number;
//   assocName: string;
//   isActive: boolean;
// }

export class EducationName {
  Id: number;
  Name: string;
  Desc: string;
  isActive: boolean;
}

export class NonOnlinerEducationName {
  Id: number;
  Name: string;
  Desc: string;
  isActive: boolean;
}

export class Skill {
  skillId: number;
  name: string;
  description: string;
  complexityRatingId: number;
  skillIndicator: string;
  isActive: boolean;
}

export class NonOnlinerSkill {
  skillId: number;
  name: string;
  description: string;
  complexityRatingId: number;
  skillIndicator: string;
  isActive: boolean;
}

export class RComplexityRating {
  complexityRatingId: number;
  name: string;
  description: string;
}

export class RSkillType {
  id: number;
  name: string;
  isActive: boolean;
}

export class RExpertiseLevel {
  id: number;
  expertiseLevel: string;
  isActive: boolean;
}

export class RExpertiseType {
  id: number;
  name: string;
  isActive: boolean;
}

export class EmployeeHome {
    userId: string;
    homeCity: string;

    constructor(userId: string, homeCity: string) { 
            this.userId = userId;
            this.homeCity = homeCity;   
    } 
}

export class RAttribute {
  id: number;
  aliasId: number;
  name: string;
  value: string;
  attributeTypeId: number;
  isActive: boolean;
  isOther: boolean;
  createdDate: Date;
  modifiedDate: Date;
  userId?: string;

  constructor(
    id: number,
    aliasId: number,
    name: string,
    value: string,
    attributeTypeId: number,
    isActive: boolean,
    isOther: boolean,
    createdDate: Date,
    modifiedDate: Date,
    userId?: string
  ) {
    this.id = id;
    this.aliasId = aliasId;
    this.name = name;
    this.value = value;
    this.attributeTypeId = attributeTypeId;
    this.isActive = isActive;
    this.isOther = isOther;
    this.createdDate = createdDate;
    this.modifiedDate = modifiedDate;
    this.userId = userId;
  }
}

export class RAttributeMaintenance {
  id: number;
  aliasId: number;
  name: string;
  value: string;
  attributeTypeId: number;
  isActive: boolean;
  isOther: boolean;
  createdDate: Date;
  modifiedDate: Date;
  isUsed: boolean;
  isReady: boolean[];

  constructor(
    id: number,
    aliasId: number,
    name: string,
    value: string,
    attributeTypeId: number,
    isActive: boolean,
    isOther: boolean,
    createdDate: Date,
    modifiedDate: Date,
    isUsed: boolean
  ) {
    this.id = id;
    this.aliasId = aliasId;
    this.name = name;
    this.value = value;
    this.attributeTypeId = attributeTypeId;
    this.isActive = isActive;
    this.isOther = isOther;
    this.createdDate = createdDate;
    this.modifiedDate = modifiedDate;
    this.isUsed = isUsed;
    this.isReady = [false, false];
  }
}

export class RAttributeType {
  id: number;
  name: string;
  hasOther: boolean;
  enum: string;
  createdDate: Date;
  modifiedDate: Date;

  constructor(
    id: number,
    name: string,
    hasOther: boolean,
    enum2: string,
    createdDate: Date,
    modifiedDate: Date
  ) {
    this.id = id;
    this.name = name;
    this.hasOther = hasOther;
    this.enum = enum2;
    this.createdDate = createdDate;
    this.modifiedDate = modifiedDate;
  }
}

export class AnalyticsValue {
  name: string;
  icon: string;
  route: string;
  isVisible: boolean;
  dateValue: Date;
  numValue: number;
}

export class CertInfoDto {
  certId: number;
  skillsAreaId: number;
  vendorId: number;
  dateModified: Date;
}

export class CompetencyLead {
  id: number;
  leadUserId: string;
  competencyId: number;
  isActive: boolean;
  leadUser?: Employee;

    constructor(
        id: number,
        leadUserId: string,
        competencyId: number,
        isActive: boolean,
        leadUser?: Employee) {
        this.id = id;
        this.leadUserId = leadUserId;
        this.competencyId = competencyId;
        this.isActive = isActive,
        this.leadUser = leadUser;
    }
}

export class CompetencyRole {
    id: number;
    name: string;
    isActive: boolean;

    constructor(
        id: number,
        name: string,
        isActive: boolean) {
        this.id = id;
        this.name = name;
        this.isActive = isActive;
    }
}

export class CompetencyLevel {
    id: number;
    shortDesc: string;
    longDesc: string;
    isActive: boolean;

    constructor(
        id: number,
        shortDesc: string,
        longDesc: string,
        isActive: boolean) {
        this.id = id;
        this.shortDesc = shortDesc;
        this.longDesc = longDesc;
        this.isActive = isActive;
    }
}

export class Competency {
  compProfile_Id: number;
  name: string;
  compLead: string;
  competencyTypeId: number;
}

export class SkillFilterCriteria {
  competency: string;
  category: string;
  expertiseLevel: string;
}

export class CompetencyProfileDefault {
    userId: string; 
    techCompProfileId?: number;
    consultCompProfileId?: number;
    techLevel: string;
    techRole: string;
    consultRole: string;
    consultLevel: string;
    costLevel?: RAttribute;

    constructor(
        userId: string,
        techLevel: string,
        consultLevel: string,
        techRole: string,
        consultRole: string,
        consultCompProfileId?: number,
        techCompProfileId?: number,
        costLevel?: RAttribute)
    {
        this.userId = userId;   
        this.techCompProfileId = techCompProfileId;
        this.consultCompProfileId = consultCompProfileId;
        this.techLevel = techLevel;
        this.consultLevel = consultLevel;
        this.techRole = techRole;
        this.consultRole = consultRole;
        this.costLevel = costLevel;
    }
}

export class CompetencyProfile {
    id: number;
    name: string;
    levelId?: number;
    roleId?: number;
    costLevel?: RAttribute;

    constructor(
        id: number, 
        name: string,
        levelId?: number,
        roleId?: number,
        costLevel?: RAttribute) {
        this.id = id;
        this.name = name;
        this.roleId = roleId;
        this.levelId = levelId;
        this.costLevel = costLevel;
    }
}

export class RegionDirector {
    id: number;
    regionId: number;
    directorId: number;
    directorName: string;
    isActive?: boolean;

    constructor(
        id: number,
        regionId: number,
        directorId: number,
        directorName: string,
        isActive?: boolean,
    ) {
        this.id = id;
        this.regionId = regionId;
        this.directorId = directorId;
        this.directorName = directorName;
        this.isActive = isActive;
    }
}

export class AccountingEmployee {
    emplId: string;
    firstName: string;
    lastName: string;
    accountingUserId: string;
    isUsed: boolean;

    constructor(
        emplId: string,
        firstName: string,
        lastName: string,
        accountingUserId: string,
        isUsed: boolean,
    ) {
        this.emplId = emplId;
        this.firstName = firstName;
        this.lastName = lastName;
        this.accountingUserId = accountingUserId;
        this.isUsed = isUsed;
    }
}

export class OnlinerProfile {
    userId: string;   
    directorId?: number;
    costLevelId?: number;
    pronounId?: number;
    pronoun?: RAttribute;
    costLevel?: RAttribute;
    director?: RegionDirector;
    careerManagerInfo?: CareerMentor;

    constructor(
        userId: string,
        directorId?: number,
        costLevelId?: number,
        pronounId?: number, 
        pronoun?: RAttribute,
        costLevel?: RAttribute,
        director?: RegionDirector,
        careerManagerInfo?: CareerMentor)
    { 
        this.userId = userId;
        this.directorId = directorId;
        this.costLevelId = costLevelId;
        this.pronounId = pronounId;
        this.pronoun = pronoun;
        this.costLevel = costLevel;
        this.director = director;
        this.careerManagerInfo = careerManagerInfo;
    }
}

export class OnlinerLanguage {
    userId: string;
    languageId: number;
    proficiencyLevelId: number;
    language?: RAttribute;
    proficiencyLevel?: RAttribute;

    constructor(
        userId: string,
        languageId: number,
        proficiencyLevelId: number,
        language?: RAttribute,
        proficiencyLevel?: RAttribute) {
        this.userId = userId;
        this.languageId = languageId;
        this.proficiencyLevelId = proficiencyLevelId;
        this.language = language;
        this.proficiencyLevel = proficiencyLevel;
    }
}

export class SavedQuery {
  id: number;
  name: string;
  searchQuery: string;
  userId: string;
  dateModified: Date;

  constructor(id: number,
    name: string,
    searchQuery: string,
    userId: string,
    dateModified: Date) {
        this.id = id;
        this.name = name;
        this.searchQuery = searchQuery;
        this.userId = userId;
        this.dateModified = dateModified;
    }
}

export class SkillCompetency {
  id: number;
  name: string;
  leads: CompetencyLead[];
  isActive: boolean;
  totalSkillCategories: number;
  isSaving?: boolean;

  constructor(
    id: number,
    name: string,
    leads: CompetencyLead[],
    isActive: boolean,
    totalSkillCategories?: number
  ) {
    this.id = id;
    this.name = name;
    this.leads = leads;
    this.isActive = isActive;
    this.totalSkillCategories = totalSkillCategories
  }
}

export class CompetencyProfileData {
    id: number;
    competencyId: number;
    competency: SkillCompetency;
    roleId: number;
    role: CompetencyRole;
    levelId: number;
    level: CompetencyLevel;
    isActive: boolean;
    competencyTypeId: number;

    constructor(
        id: number,
        competencyId: number,
        competency: SkillCompetency,
        roleId: number,
        role: CompetencyRole,
        levelId: number,
        level: CompetencyLevel,
        isActive: boolean,
        competencyTypeId: number,
    ) {
        this.id = id;
        this.competency = competency;
        this.competencyId = competencyId;
        this.roleId = roleId;
        this.role = role;
        this.levelId = levelId;
        this.level = level;
        this.isActive = isActive;
        this.competencyTypeId = competencyTypeId;
    }
}

export class RCompetencySkillCategory {
  id: number;
  skillId: number;
  skill: RAttribute;
  competencyId: number;
  competency: SkillCompetency;
  categoryId: number;
  category: RAttribute;
  isOther: boolean;
  isActive: boolean;
  isCommon: boolean;

  constructor(
    id: number,
    skillId: number,
    skill: RAttribute,
    competencyId: number,
    competency: SkillCompetency,
    categoryId: number,
    category: RAttribute,
    isOther: boolean,
    isActive: boolean,
    isCommon: boolean
  ) {
    this.id = id;
    this.skill = skill;
    this.skillId = skillId;
    this.competencyId = competencyId;
    this.competency = competency;
    this.categoryId = categoryId;
    this.category = category;
    this.isOther = isOther;
    this.isActive = isActive;
    this.isCommon = isCommon;
  }
}
